import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import styled from 'styled-components';
import { Header, Layout, SEO } from '../components';
import PathContext from '../models/PathContext';
import Post from '../models/Post';
import '../utils/prismjs-theme.css';

interface Props {
  data: {
    markdownRemark: Post;
  };
  pathContext: PathContext;
}

const PostWrapper = styled.article`
  font-size: 1.25rem;
  line-height: 1.5rem;
  max-width: 45rem;
  padding: 1rem;
  margin: 0 auto auto auto;
`;

const Image = styled.img`
  display: block;
  margin: 4rem auto;
  max-width: 100%;
`;

const Byline = styled.div`
  display: flex;
  justify-content: center;
`;

const BylineSection = styled.div`
  font-size: 0.9rem;
  color: #4e4e4e;
  min-width: 2rem;
  text-align: center;
`;

const ArticleContent = styled.div`
  h1 {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  h2 {
    font-size: 1.6rem;
    line-height: 1.85rem;
  }
  h3 {
    font-size: 1.4rem;
    line-height: 1.65rem;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

const PostPage = ({ data: { markdownRemark: post } }: Props) => {
  const authors = (post?.frontmatter?.authors || []).map((author, idx) => {
    const [name, url] = author.split('|');
    return (
      <strong key={idx}>
        {url ? (
          <a href={url} target='_blank' rel='noopener'>
            {name}
          </a>
        ) : (
          name
        )}
        {idx < post.frontmatter.authors.length - 1 ? `, ` : ``}
      </strong>
    );
  });

  const tags = (post?.frontmatter?.tags || []).map((tag, i) => (
    <Link key={i} to={`/tags/${kebabCase(tag)}`}>
      <strong>{tag}</strong>
      {i < post.frontmatter.tags.length - 1 ? `, ` : ``}
    </Link>
  ));

  return (
    <Layout>
      <SEO isPost={true} postPath={post.fields.slug} postNode={post} />
      <Header title={post.frontmatter.title} icon={post.frontmatter.icon || 'document-edit'} />
      <PostWrapper>
        <Byline>
          <BylineSection>
            {post.frontmatter.date} &mdash; {post.timeToRead} Min Read &mdash; In{' '}
            <Link to={`/categories/${kebabCase(post.frontmatter.category)}`}>{post.frontmatter.category}</Link>
          </BylineSection>
          {authors && (
            <React.Fragment>
              <BylineSection>|</BylineSection>
              {authors.length === 1 && <BylineSection>Author: &#160; {authors[0]}</BylineSection>}
              {authors.length > 1 && <BylineSection>{authors}</BylineSection>}
            </React.Fragment>
          )}
        </Byline>

        <Image src={post.frontmatter.image?.publicURL || '/images/blog.jpg'} />
        <ArticleContent dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr />
        {tags}
      </PostWrapper>
    </Layout>
  );
};

export default PostPage;

export const PostQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        category
        tags
        banner
        authors
        image {
          publicURL
        }
      }
      timeToRead
    }
  }
`;
